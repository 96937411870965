import React, {forwardRef} from 'react';
import {Container, AppBar, Slide, Toolbar, useScrollTrigger} from '@material-ui/core';
import {ThemeProvider, makeStyles} from '@material-ui/core/styles';
import {LoadingIndicator, UserMenu, Notification, Logout, useLogout} from 'react-admin';
import logo from '../logo.gif';
import {createMuiTheme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    offset: {
        ...theme.mixins.toolbar
    },
}));

const LogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <Logout onClick={handleClick}/>
    );
});


const appBarTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#ffffff',
            dark: '#c6c6c6',
            contrastText: '#212121',
        }
    },
});


const Layout = (props) => {

    const classes = useStyles(props.theme);
    const trigger = useScrollTrigger();

    return (
        <Container maxWidth="md">
            <ThemeProvider theme={appBarTheme}>
                <Slide appear={false} direction="down" in={!trigger}>
                    <AppBar>
                        <Toolbar>
                            <div className={classes.title}>
                                <img src={logo} alt={"komitee akten logo"}/>
                            </div>
                            <LoadingIndicator/>
                            <UserMenu>
                                <LogoutButton {...props}/>
                            </UserMenu>
                        </Toolbar>
                    </AppBar>
                </Slide>
            </ThemeProvider>
            <div className={classes.offset}/>

            <ThemeProvider theme={props.theme}>
                <div>
                    {props.children}
                </div>
                <Notification/>
            </ThemeProvider>
        </Container>
    );
};


export default Layout