import {Route} from "react-router-dom";
import {hydraDataProvider, fetchHydra} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import RedirectToLogin from "../components/RedirectToLogin";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const authorizedFetchHydra = (url, options = {}) =>
    fetchHydra(url, {
        ...options,
        headers: getHeaders,
    });


const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};

const DataProvider = hydraDataProvider(entrypoint, authorizedFetchHydra, apiDocumentationParser);


export default DataProvider;
