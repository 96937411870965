import React from "react";
import {HydraAdmin, ResourceGuesser} from "@api-platform/admin";
import AuthProvider from "./services/authProvider";
import DataProvider from "./services/dataProvider";


import Layout from "./components/Layout";
import DocumentList from "./components/DocumentList";
import DocumentEdit from "./components/DocumentEdit";
import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#484848',
            main: '#424242',
            dark: '#1b1b1b',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#e75e4d',
            main: '#af2c24',
            dark: '#790000',
            contrastText: '#ffffff',
        },
    },
});

function App() {
    return (
        <HydraAdmin
            theme={theme}
            entrypoint={process.env.REACT_APP_API_ENTRYPOINT}
            dataProvider={DataProvider}
            authProvider={AuthProvider}
            layout={Layout}
        >
            <ResourceGuesser
                name="documents"
                list={DocumentList}
                edit={DocumentEdit}
            />

        </HydraAdmin>
    );
}

export default App;
