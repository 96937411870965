import React from "react";
import {
    usePermissions,
    Filter,
    List,
    TextInput,
    useListContext,
    linkToRecord,
    Link
} from 'react-admin';
import {Typography, Box, Button} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const DocumentFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="Search" source="sword" alwaysOn/>
        </Filter>
    )
};


const MyDocumentListItem = (props) => {

    const {loaded, permissions} = usePermissions();
    return loaded ? (
        <Box mt={5} mb={5}>
            <Typography variant="h4">
                {permissions.includes('ROLE_ADMIN') ?
                    <Link to={linkToRecord(props.basePath, props.record.id, 'edit')}>
                        {props.record.title}
                    </Link>
                    :
                    props.record.title
                }
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                (EAN: {props.record.ean})
            </Typography>

            {props.record.highlights.map((highlight, index) =>
                <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{__html: highlight.content}}
                            key={index}/>
            )}

            <Box display="inline">
                <Button variant="contained" color="secondary" target="_blank"
                        href={process.env.REACT_APP_API_ENTRYPOINT + props.record.filename}>
                    <PictureAsPdfIcon/>
                </Button>
            </Box>
        </Box>
    ) : null;
};

const MyDocumentList = () => {
    const {ids, data, basePath} = useListContext();
    return (
        <div style={{margin: '1em'}}>
            {ids.map(id =>
                <MyDocumentListItem record={data[id]} key={id} basePath={basePath}/>
            )}
        </div>
    );
};

const DocumentList = props => {
    return (
        <List {...props} filters={<DocumentFilter/>} actions={<></>} bulkActionButtons={false}>
            <MyDocumentList/>
        </List>
    )
};

export default DocumentList