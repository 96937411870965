import {Redirect} from "react-router-dom";
import {useIntrospection} from "@api-platform/admin";

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};

export default RedirectToLogin;