import React from "react";
import {
    Toolbar,
    SaveButton,
    Edit,
    SimpleForm,
    TextInput,
    ListButton,
    required
} from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

const DocumentEditToolbar = props => {

    return (
        <Toolbar {...props}>
            <ListButton basePath={props.basePath} label="Back to List" size="large" icon={<ChevronLeft />} />
            <SaveButton color={"secondary"}/>
        </Toolbar>
    );
};

const DocumentEdit = (props) => {

    return (
        <Edit {...props} actions={<></>}>
            <SimpleForm toolbar={<DocumentEditToolbar/>} redirect={"list"}>
                <TextInput source="title" validate={required()}/>
            </SimpleForm>
        </Edit>
    );
};

export default DocumentEdit;